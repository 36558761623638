<template>
  <v-row>
    <v-col cols="12">

      <v-card>
        <v-card-title primary-title>
          Consulta de Documentos de Entradas y Salidas
          <v-spacer></v-spacer>
          
          <v-btn color="success" 
            small 
            @click="consultar()">
            Consultar

            <v-icon small>mdi-magnify</v-icon>
          </v-btn>
          
          <!-- BOTON DE XLS   -->
          <!-- <v-btn 
            class="ma-2" 
            small dark color="warning"
            @click="exportar()">
            <v-icon>mdi-download</v-icon>
          </v-btn> -->
          
          <!-- BOTON PDF -->
         <!--  <v-btn
            class='ma-2' 
            small color="secondary"
            @click="crearPDF()">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
 -->
        </v-card-title>

        <v-card-text>
          <v-row>

            <!-- FECHA INICIAL -->
            <v-col cols="12" xl="2" lg="2" md="3" sm="4" xs="4">
              <v-menu

                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date1"
                    label="Fecha Inicial"
                    prepend-icon="mdi-calendar-arrow-right"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                
                <v-date-picker 
                  locale="es"
                  v-model="date1" 
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
        
            <!-- FECHA FINAL -->
            <v-col cols="12" xl="2" lg="2" md="3" sm="4" xs="4">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date2"
                    label="Fecha Final"
                    prepend-icon="mdi-calendar-arrow-left"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker 
                  locale="es"
                  v-model="date2" 
                  @input="menu2 = false"></v-date-picker>
              </v-menu>
            </v-col>

            <!-- TIPODOC  -->
            <v-col cols="12" xl="2" lg="2" md="3" sm="3" xs="3">
              <v-select
                @change="consultar()"
                :items="tipodocs"
                v-model="tipodoc"
                label="Tipo documento"                
              ></v-select>
            </v-col>
          
             
            <!-- BUSCAR -->
            <v-col cols="12" xl="4" lg="4" md="3" sm="3" xs="3">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar "
                single-line
                hide-details
              ></v-text-field>
            </v-col>


            <!-- DATATABLE -->
            <v-col cols="12">
              <v-data-table
                :loading="loading" 
                loading-text="Cargando..."
                :headers="headers"
                :items="condoc"
                :items-per-page="longitud"
                class="elevation-1"
                fixed-header
                height="350"
                :search="search"
                hide-default-footer
                dense
              >
                <template v-slot:item="{ item }" >
                  <tr v-on:click="edit(item)">
                    <td>{{ item.numdoc}}</td>
                    
                    <td align= 'end'>{{ item.tipodoc}}</td>
                    <td>{{ item.fecha}}</td>
                    <td align= 'end'>{{ item.importe}}</td>
                    <td align= 'end'>{{ item.obs}}</td>
                    <td align= 'end'>{{ item.numalm}}</td>
                    <td align= 'end'>{{ item.numcli}}</td>      
                  </tr>
               </template>
              </v-data-table>

            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    
    </v-col>  
  </v-row>  
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'

  var accounting = require("accounting");
  var base64 = require('base-64');
  // import excelMixin from '@/mixins/excelMixin.js';
  // import jsPDF from 'jspdf'
  // import autoTable from 'jspdf-autotable';
  
  export default {
    data () {
      return {
        //FECHAS
        date1: new Date().toISOString().substr(0, 10),
        date2: new Date().toISOString().substr(0, 10),
        
        menu1: false,
        menu2: false,

        // SELECTOR TIPODOC
        tipodocs: ['Entradas', 'Salidas'],
        tipodoc: 'Entradas',
        e1:'Pedidos',
        loading: true,
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        search: '',
        longitud: 5,
       
        headers: [
          { text: 'Folio', align: 'start', value: 'numdoc' },    
          { text: 'Tipo',  align: 'start',value: 'tipodoc' },
          { text: 'Fecha',     align: 'start'  , value: 'fecha' },
          { text: 'Importe',   align: 'end',  value: 'importe'   },
          { text: 'Observaciones',  value: 'descuento' , align: 'end'},

          { text: 'Almacen',  align: 'end',  value: 'numalm' },
          { text: 'Cliente',  align: 'end',  value: 'numcli' },
         
        ],

        Docum: [],
        condoc:[]
      }
    },

    computed:{
      ...mapGetters('Login',['getdatosUsuario','getClientes','getModo','getInfoVend']),
    },

    created () {
      //Recibir parametro de tipo de Documento a Abrir. 
       // console.log("Params",this.$route.params)
      this.tipodocumento= this.$route.params.tipodocumento
      this.tipodocumento = "Entradas"

      // Definir valores iniciales 
      // Fecha inicial Primero de mes.
      let date0 = new Date()
      let mes = date0.getMonth()
      let anio = date0.getFullYear()
            
      // console.log(mes, anio)
      this.date1 = new Date(anio, mes, 1).toISOString().substr(0, 10)
      this.consultar()

    },

    methods:{
      ...mapActions('documento',['addDocumento']),

      // FORMATO PDF
      // crearPDF() {
      //   var columns = [
      //       { title: 'ID',        dataKey: 'Numdoc' },
      //       { title: 'Fecha' ,    dataKey: 'Fecha' },
      //       { title: 'Importe',   dataKey: 'importe' },
      //       { title: 'Descuento', dataKey: 'descuento' },
      //       { title: 'Iva',       dataKey: 'impuesto1' },
      //       { title: 'Total',     dataKey: 'total' },
      //       { title: 'Divisa',    dataKey: 'divisa' },
      //       { title: 'Tc',        dataKey: 'Tc' },
      //       { title: 'Estatus',   dataKey: 'Status' },
      //   ];

      //   var doc = new jsPDF('p','pt');
      //   doc.autoTable(columns, this.Docum, {
      //       styles: {minCellWidth: 50, halign: 'left'},
      //       columnStyles: {
      //         0: {cellWidth: 'auto'},
      //         1: {cellWidth: 'auto'},
      //         2: {cellWidth: 'auto'},
      //         3: {cellWidth: 'auto'},
      //         4: {cellWidth: 'auto'},
              
      //         },
      //       margin: {top: 60},
      //       didDrawPage: function(data) {
      //         doc.text("Consulta de Documentos", 40, 30);
      //     }
      //   });
      //   doc.save('Documentos.pdf');
      // },


      // Consulto todos los clientes
      consultar() {
        this.Docum = []
        // console.log(this.tipodoc)

        let payload = {
          numvend: '', 
          numcli: '', 
          fecha1: this.date1, 
          fecha2: this.date2, 
          tipodoc: "EN"
        }
        
        if(this.tipodoc == 'Entradas'){
          payload.tipodoc = 'EN'
        }else{
          payload.tipodoc = 'SE'
        }

        this.loading = true

        // if(this.getModo == 'vendedor'){
        //   payload.numvend = this.getInfoVend.Numvend
        // }else{
        //   payload.numcli = this.getdatosUsuario.numcli
        // }


        this.condoc = []
        console.log("payload de done es? ",payload)
        this.$http.post('v2/entradaskam.condocum',payload).then(response => {
          console.log(response)
           //Calcular el Total. 
          if (response.data.result !== null) {
            this.condoc = response.data.result
            this.condoc.forEach ( element => {
              element.cant = accounting.unformat(element.cant)
              element.disp = accounting.unformat(element.disp)
              element.cant = accounting.formatNumber(element.cant,2)
              element.disp = accounting.formatNumber(element.disp,2)
            })
            this.longitud= this.condoc.length
            console.log("condoc", this.condoc)

          }

        }).catch(function(error){
          console.log(error)
        }).finally(()  => this.loading = false) 

      },


      edit(tipodoc){
        console.log("edit", tipodoc)
        this.addDocumento(tipodoc)
        var encodedData = base64.encode(tipodoc.Iddocum);
        var doc = {doc: encodedData}
        this.$router.push({name:'documento', params: {info: doc, origen: 'cotizacion', tipodoc:tipodoc.tipodoc, numdoc: tipodoc.numdoc }}).catch(err => {console.log(err)})
      },

      getColor (status) {
        if (status == 0) return 'red'
          else if (status == 9) return 'green'
        else return 'green'
      },

      exportar(){
        let xls = {
          data:      this.Docum,
          tHeaders : ['ID doc', 'Nombre', 'Tipo', 'Fecha', 'Total', 'Divisa', 'Estatus',],
          filterVal: ['Numdoc', 'nomcli', 'Tipodoc', 'Fecha', 'importe', 'divisa', 'Status'],
          nomfile :  "Consulta de Documentos"
        }
        // console.log(xls)
         this.exportarXls(xls)
      },


    }
  };
</script>